@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

@import "components/timepicker";

.clock {
	font-family: 'Inconsolata', monospace;
	text-align: center;

	font-size: 60px;
}

#loader {
	position: absolute;
	z-index: 9999;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;

	background-color: rgba(0,0,0, 0.5);

	i {
		position: absolute;
		left: 50%;
		top: 50%;
		margin-left: -30px;
		margin-top: -30px;

		color: white;

		font-size: 60px;
		animation: fa-spin 2s infinite linear;
	}
}

@keyframes fa-spin {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(359deg);
		transform: rotate(359deg);
	}
}