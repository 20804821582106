.timepicker {
	@extend .row;
	text-align: center;

	&__hours {
		@extend .col-xs-6;
	}

	input.form-control {
		text-align: center;
	}
}